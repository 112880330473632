// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B7Q1CTFohSSbnrWkteHQ{display:flex;align-items:center;justify-content:space-between;padding:25px 27px;border-radius:10px;transition:all .3s ease 0s;cursor:pointer}.B7Q1CTFohSSbnrWkteHQ:hover .VsgIYB2ppd3JBnhTC0Oz{transform:scale(1.05)}.yY9gYo3sSgU7b3lVa4Xj{cursor:default !important}.ZqNdIn1rDmJMs5bXXH9Q{font-family:Mulish;font-size:14px;font-weight:700;line-height:114%;margin-bottom:5px;color:#fff}.CnMCwkm3QMQw7nsxSF6b{font-family:Mulish;font-size:12px;font-weight:500;color:#fff;max-width:225px}.VsgIYB2ppd3JBnhTC0Oz{position:relative;z-index:2}`, "",{"version":3,"sources":["webpack://./src/components/promotion-block/promotion-block.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CAEE,kDACE,qBAAA,CAGJ,sBACE,yBAAA,CAEF,sBACI,kBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CAEJ,sBACI,kBAAA,CACA,cAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CAEJ,sBACE,iBAAA,CACA,SAAA","sourcesContent":[".block {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 25px 27px;\n    border-radius: 10px;\n    transition: all 0.3s ease 0s;\n    cursor: pointer;\n    &:hover {\n      .blockAction {\n        transform: scale(1.05);\n      }\n    }\n    &Disabled {\n      cursor: default !important;\n    }\n    &Title {\n        font-family: Mulish;\n        font-size: 14px;\n        font-weight: 700;\n        line-height: 114%;\n        margin-bottom: 5px;\n        color: #fff;\n    }\n    &Description {\n        font-family: Mulish;\n        font-size: 12px;\n        font-weight: 500;\n        color: #fff;\n        max-width: 225px;\n    }\n    &Action {\n      position: relative;\n      z-index: 2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `B7Q1CTFohSSbnrWkteHQ`,
	"blockAction": `VsgIYB2ppd3JBnhTC0Oz`,
	"blockDisabled": `yY9gYo3sSgU7b3lVa4Xj`,
	"blockTitle": `ZqNdIn1rDmJMs5bXXH9Q`,
	"blockDescription": `CnMCwkm3QMQw7nsxSF6b`
};
export default ___CSS_LOADER_EXPORT___;
