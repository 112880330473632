/* eslint-disable import/no-default-export */
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        whitelist: [ 'ru', 'en', 'ua' ],
        debug: false,
        detection: {
            order: [ 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag' ],
            caches: [ 'cookie', 'localStorage' ],
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0
        },
        interpolation: { escapeValue: false }
    })

export default i18n
