// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jAkw83CtHrncesFf5zNw{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.Bz0B0yAfNsbf4mWzkpAY{display:flex;align-items:center;justify-content:center;gap:10px;margin-bottom:5px}.uBQwxZBVVUJwz_lJxEha{font-size:21px;font-weight:500;text-align:center;color:var(--tg-theme-text-color)}.WFFA9ShXQVBbSAmKiQPB{margin:0 auto;text-align:center;color:var(--tg-theme-text-color);margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction-steps/fourth-step/fourth-step.module.scss"],"names":[],"mappings":"AACE,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA,CAEF,sBACE,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,kBAAA","sourcesContent":[".thirdStep {\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Top {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    margin-bottom: 5px;\n  }\n  &Title {\n    font-size: 21px;\n    font-weight: 500;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n  }\n  &Description {\n    margin: 0 auto;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thirdStepImg": `jAkw83CtHrncesFf5zNw`,
	"thirdStepTop": `Bz0B0yAfNsbf4mWzkpAY`,
	"thirdStepTitle": `uBQwxZBVVUJwz_lJxEha`,
	"thirdStepDescription": `WFFA9ShXQVBbSAmKiQPB`
};
export default ___CSS_LOADER_EXPORT___;
