import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { Title } from '@delab-team/de-ui';
import * as oopsSticker from '../../assets/stickers/oops.json';
import s from './styles.module.scss';
export const SomethingWentWrong = () => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: oopsSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.innerImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx(Title, { variant: "h2", className: s.innerTitle, children: t('common.something-went-wrong') })] }));
};
