import { beginCell, contractAddress, SendMode } from '@ton/core';
export function jettonWalletConfigToCell(config) {
    return beginCell()
        .storeCoins(0)
        .storeAddress(config.owner)
        .storeAddress(config.minter)
        .storeRef(config.walletCode)
        .endCell();
}
export class JettonWallet {
    constructor(address, init) {
        this.address = address;
        this.init = init;
    }
    static createFromAddress(address) {
        return new JettonWallet(address);
    }
    static createFromConfig(config, code, workchain = 0) {
        const data = jettonWalletConfigToCell(config);
        const init = { code, data };
        return new JettonWallet(contractAddress(workchain, init), init);
    }
    async sendDeploy(provider, via, value) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().endCell()
        });
    }
    async sendTransfer(provider, via, value, forwardValue, recipient, amount, forwardPayload) {
        await provider.internal(via, {
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell()
                .storeUint(0x0f8a7ea5, 32)
                .storeUint(0, 64)
                .storeCoins(amount)
                .storeAddress(recipient)
                .storeAddress(via.address)
                .storeUint(0, 1)
                .storeCoins(forwardValue)
                .storeUint(1, 1)
                .storeRef(forwardPayload)
                .endCell(),
            value: value + forwardValue
        });
    }
    async getJettonBalance(provider) {
        const state = await provider.getState();
        if (state.state.type !== 'active') {
            return 0n;
        }
        const res = await provider.get('get_wallet_data', []);
        return res.stack.readBigNumber();
    }
}
