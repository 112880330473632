// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oAETKeBMWT_VmX6aYKbR{height:100vh}.SClSvNi4F9WjNGQBJJ1A{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.B0n24A0pqm9RBooQmF8P{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.kZDr9h9SIpoN5UiF0ZMy{font-size:21px;font-weight:500;text-align:center;color:var(--tg-theme-text-color);margin-bottom:20px}.M7QBlz7xB79bLOFxetMU{margin:0 auto;text-align:center;max-width:270px;color:var(--tg-theme-text-color);margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction-steps/sixth-step/sixth-step.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA,CACA,kBAAA,CAEF,sBACE,aAAA,CACA,iBAAA,CACA,eAAA,CACA,gCAAA,CACA,kBAAA","sourcesContent":[".sixthStep {\n  height: 100vh;\n  &Inner {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n  }\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Title {\n    font-size: 21px;\n    font-weight: 500;\n    text-align: center;\n    color: var(--tg-theme-text-color);\n    margin-bottom: 20px;\n  }\n  &Description {\n    margin: 0 auto;\n    text-align: center;\n    max-width: 270px;\n    color: var(--tg-theme-text-color); \n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sixthStep": `oAETKeBMWT_VmX6aYKbR`,
	"sixthStepInner": `SClSvNi4F9WjNGQBJJ1A`,
	"sixthStepImg": `B0n24A0pqm9RBooQmF8P`,
	"sixthStepTitle": `kZDr9h9SIpoN5UiF0ZMy`,
	"sixthStepDescription": `M7QBlz7xB79bLOFxetMU`
};
export default ___CSS_LOADER_EXPORT___;
