import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Li, Spinner, Text, Title } from '@delab-team/de-ui';
import { useTonConnectUI } from '@tonconnect/ui-react';
import Lottie from 'react-lottie';
import TonWeb from 'tonweb';
import { v1 } from 'uuid';
import { ProviderTonConnect } from '@delab-team/ton-network-react';
import { useTranslation } from 'react-i18next';
import { TonApi } from '@delab-team/ton-api-sdk';
import { Address, beginCell, fromNano, toNano } from '@ton/core';
import * as paySticker from '../../../../assets/stickers/pay.json';
import * as planSticker from '../../../../assets/stickers/plan.json';
import { modalMocks } from '../../../../mocks/tokens';
import { JettonWallet } from '../../../../utils/JettonWallet';
import { JettonMinter } from '../../../../utils/JettonMinter';
import { VPN } from '../../../../logic/vpn';
import s from './third-step.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
export const ThirdStep = ({ activePayToken, amount, user, currentStep, rawAddress, handlePrevStep }) => {
    const TgObj = window.Telegram.WebApp;
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: paySticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const approveOptions2 = {
        loop: true,
        autoplay: true,
        animationData: planSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const [modalData, setModalData] = useState(undefined);
    const [tonConnectUI] = useTonConnectUI();
    const [activeVariant, setActiveVariant] = useState(undefined);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [isPaymentPage, setIsPaymentPage] = useState(false);
    const api = new TonApi('AFJOFCU7XVPBKMIAAAAM6H4MQHHW57TAGBG2EY3C3A6USOZUSGXEI6MRDW32YKVQDZBCGUQ', 'mainnet');
    const vpn = new VPN();
    useEffect(() => {
        const isPaymentPage = localStorage.getItem('toPaymentPage');
        if (isPaymentPage) {
            setIsPaymentPage(JSON.parse(isPaymentPage));
        }
    }, []);
    const auth = !!localStorage.getItem('ton-connect-ui_wallet-info');
    const handleMainButtonClick = async () => {
        if (activeVariant && currentStep === 3 && user) {
            await handlePay();
            TgObj.MainButton.color = '#40a7e3';
        }
    };
    useEffect(() => {
        if (!amount) {
            return;
        }
        const fetchTokenPrices = async () => {
            try {
                const tokenAddresses = modalMocks.map(item => item.tokenAddress[0]);
                // @ts-ignore
                const res = await api.Rates.getTokenPrice(tokenAddresses, 'usd');
                const updatedModalData = modalMocks.map((item) => {
                    // @ts-ignore
                    const prices = res?.rates[item.tokenAddress[0]]?.prices?.USD;
                    let amountInUsd;
                    if (!prices) {
                        const increasedAmount = (amount / 0.01) * 1.05;
                        amountInUsd = increasedAmount.toFixed(2);
                    }
                    else {
                        const reverseRate = 1 / prices;
                        const increasedAmount = amount * reverseRate * 1.05;
                        amountInUsd = increasedAmount.toFixed(2);
                    }
                    return {
                        ...item,
                        amount: amountInUsd
                    };
                });
                setModalData(updatedModalData);
            }
            catch (error) {
                console.error('Error fetching token prices:', error);
            }
        };
        fetchTokenPrices();
    }, [amount, activePayToken, modalMocks]);
    function backBtn(handlePrevStep) {
        TgObj.BackButton.show();
        TgObj.BackButton.onClick(() => {
            if (!isPaymentPage) {
                handlePrevStep();
                window.location.href = '/introduction';
                localStorage.setItem('activeRate', JSON.stringify(''));
                localStorage.setItem('currentIntroductionStep', '2');
            }
            else {
                TgObj.BackButton.hide();
                TgObj.MainButton.hide();
                localStorage.removeItem('toPaymentPage');
                localStorage.setItem('hasPassedIntroduction', 'true');
                window.location.href = '/promotions';
            }
        });
    }
    useEffect(() => {
        if (!auth) {
            tonConnectUI.connectWallet();
            TgObj.MainButton.hide();
            return;
        }
        TgObj.MainButton.show();
        if (activeVariant && currentStep === 3 && user && rawAddress) {
            TgObj.MainButton.enable();
            TgObj.MainButton.show();
            TgObj.MainButton.color = '#40a7e3';
            TgObj.MainButton.hideProgress();
        }
        else {
            TgObj.MainButton.color = '#9a9c9a';
            TgObj.MainButton.showProgress();
            TgObj.MainButton.disable();
        }
        if (!activeVariant) {
            TgObj.MainButton.hideProgress();
        }
        TgObj.MainButton.text = (!activeVariant
            ? t('introduction-page.third-step.choose-btn')
            : `${t('common.pay-btn')} ${activeVariant?.amount || ''} ${activeVariant?.token || ''}`);
        backBtn(handlePrevStep);
        TgObj.MainButton.onClick(handleMainButtonClick);
        return () => {
            TgObj.MainButton.offClick(handleMainButtonClick);
        };
    }, [activeVariant, rawAddress, currentStep === 3, user]);
    async function sendTrans(id_user, currency, amount) {
        const addressVPN = 'EQDYl5uFtd5O0EI19GLnMZPKPMtopdLlyvTexPmeJgkAAfq3';
        if (currency === 'TON') {
            const a = new TonWeb.boc.Cell();
            a.bits.writeUint(0, 32);
            a.bits.writeString(id_user + '');
            const payload = TonWeb.utils.bytesToBase64(await a.toBoc());
            const tr = {
                validUntil: Math.floor(Date.now() / 1000) + 60,
                messages: [
                    {
                        address: addressVPN,
                        amount: toNano(amount).toString(),
                        payload
                    }
                ]
            };
            const tx = await tonConnectUI.sendTransaction(tr);
            return tx;
        }
        const test = new ProviderTonConnect(tonConnectUI, false);
        const JettonMinterContract = new JettonMinter(currency);
        const jettonMinter = test.open(JettonMinterContract);
        try {
            const walletAddressUser = await jettonMinter.getWalletAddressOf(Address.parse(rawAddress));
            const JettonWalletContract = new JettonWallet(walletAddressUser);
            const jettonWallet = test.open(JettonWalletContract);
            await jettonWallet.sendTransfer(test.sender(), toNano('0.1'), toNano('0.08'), Address.parse(addressVPN), toNano(amount), beginCell().storeUint(0, 32).storeStringRefTail(id_user + '').endCell());
            return true;
        }
        catch (e) {
            console.log(e);
            return false;
        }
    }
    // Pay function
    async function handlePay() {
        if (!user || !activeVariant || currentStep !== 3) {
            return;
        }
        TgObj.MainButton.hide();
        const userBalance = await api.Accounts.getAllJettonsBalances(rawAddress);
        let hasSufficientBalance;
        if (activeVariant.token === 'TON') {
            hasSufficientBalance = true;
        }
        else {
            const rawAddressJetton = Address.parse(activeVariant?.tokenAddress[0]).toRawString();
            const jettonBalance = userBalance?.balances.find(el => Address.parse(el?.jetton?.address).toRawString() === rawAddressJetton);
            if (jettonBalance) {
                if (Number(fromNano(jettonBalance.balance)) >= Number(activeVariant.amount)) {
                    hasSufficientBalance = true;
                }
            }
        }
        if (!hasSufficientBalance) {
            TgObj.MainButton.show();
            TgObj.showAlert(t('common.insufficient-balance'));
            return false;
        }
        const tr = await sendTrans(Number(user.user?.id), activeVariant?.token === 'TON' ? 'TON' : Address.parse(activeVariant.tokenAddress[0]), Number(activeVariant?.amount));
        if (tr) {
            setIsPaymentLoading(true);
            let interval;
            interval = setInterval(async () => {
                const userData = await vpn.postAuth();
                if (userData?.user?.type_subscribe !== 0 && userData?.user?.type_subscribe !== 3) {
                    clearInterval(interval);
                    setIsPaymentLoading(false);
                    if (isPaymentPage) {
                        localStorage.removeItem('toPaymentPage');
                        localStorage.setItem('hasPassedIntroduction', 'true');
                        TgObj.showAlert(t('common.congratulations'));
                        window.location.href = '/';
                    }
                    else {
                        localStorage.setItem('currentIntroductionStep', '4');
                        TgObj.showAlert(t('common.congratulations'));
                        TgObj.MainButton.show();
                        window.location.href = '/introduction';
                    }
                }
            }, 5000);
            setTimeout(() => {
                clearInterval(interval);
                setIsPaymentLoading(false);
                if (user?.user?.type_subscribe === 0) {
                    TgObj.showAlert('Error, please try again');
                    TgObj.MainButton.show();
                }
            }, 120000);
        }
        else {
            TgObj.MainButton.show();
            TgObj.showAlert('Error, please try again');
            setIsPaymentLoading(false);
        }
    }
    useEffect(() => {
        backBtn(handlePrevStep);
    }, [isPaymentPage]);
    return (_jsx("div", { className: s.fourthStep, children: !isPaymentLoading ? (_jsxs(_Fragment, { children: [_jsx("div", { className: s.fourthStepImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx("div", { className: s.modalTop, children: _jsxs(Title, { variant: "h2", className: s.modalTitle, tgStyles: textTgStyles, children: [t('introduction-page.third-step.title'), ": ", amount, "$"] }) }), _jsx(Text, { className: s.modalDescription, children: t('introduction-page.third-step.description') }), _jsx("ul", { className: s.modalTokensList, children: modalData?.map(el => (_jsxs(Li, { className: `${s.tokenItem} ${el.token === activeVariant?.token ? s.tokenItemActive : ''}`, onClick: () => {
                            setActiveVariant(el);
                        }, tgStyles: {
                            border: el.token === activeVariant?.token
                                ? '1px solid var(--tg-theme-button-color)'
                                : '1px solid var(--tg-theme-secondary-bg-color)',
                            backgroundColor: el.token === activeVariant?.token
                                ? 'var(--tg-theme-secondary-bg-color)'
                                : 'transparent'
                        }, children: [_jsx("img", { className: s.tokenInfoImg, src: el.tokenLogo, alt: "token logo" }), _jsx("div", { className: s.tokenDetail, children: _jsxs(Text, { className: s.tokenItemAmount, tgStyles: textTgStyles, children: [el.amount, " ", el.token] }) })] }, v1()))) })] })) : (_jsx("div", { className: s.loading, children: _jsxs("div", { className: s.loadingInner, children: [_jsx("div", { className: s.fourthStepImg, children: _jsx(Lottie, { options: approveOptions2, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx(Text, { className: s.loadingText, tgStyles: textTgStyles, children: "Please wait while the payment is being processed. This may take up to two minutes." }), _jsx(Spinner, { className: s.loadingSpinner, color: "#000", size: 'large', tgStyles: 'var(--tg-theme-button-color)' })] }) })) }));
};
