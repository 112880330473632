import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { Title, Text } from '@delab-team/de-ui';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { DownloadModal } from '../../components/download-modal';
import * as instructionSticker from '../../assets/stickers/instruction.json';
import { ROUTES } from '../../utils/router';
import s from './download.module.scss';
export const Download = () => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: instructionSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const TgObj = window.Telegram.WebApp;
    function enableBtn() {
        TgObj.BackButton.onClick(() => navigate(ROUTES.HOME));
    }
    useEffect(() => {
        enableBtn();
    }, []);
    return (_jsxs("div", { className: s.download, children: [_jsx("div", { className: s.downloadImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx(Title, { variant: "h2", className: s.downloadTitle, children: t('download-page.title') }), _jsx(Text, { className: s.downloadDescription, children: t('download-page.subtitle') }), _jsx(DownloadModal, {})] }));
};
