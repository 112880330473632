import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PageWrapper, MobileMenu, IconSelector, HeaderPanel, Text } from '@delab-team/de-ui';
import { Link, useNavigate } from 'react-router-dom';
import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { ROUTES } from '../utils/router';
import s from './layout.module.scss';
const wrapperTgStyles = { headerStyles: { background: 'var(--tg-theme-bg-color)' } };
const headerStyles = { header: { background: 'var(--tg-theme-bg-color)' } };
const menuTgStyles = { menuContainer: { background: 'var(--tg-theme-secondary-bg-color)' } };
export const Layout = ({ children, isTg, showIntroduction, isError }) => {
    const navigate = useNavigate();
    const [tonConnectUI] = useTonConnectUI();
    const rawAddress = useTonAddress();
    const path = window.location.pathname;
    const MobileMenuItems = [
        {
            icon: (_jsx(IconSelector, { id: "home", size: "30px", className: `${s.actionIcon}`, color: path === ROUTES.HOME
                    ? isTg
                        ? 'var(--tg-theme-text-color)'
                        : '#fff'
                    : '#98989E' })),
            onClick: () => {
                navigate(ROUTES.HOME);
            },
            tgStyles: { item: { opacity: path === ROUTES.HOME ? '1' : '0.8' } }
        },
        {
            icon: (_jsx(IconSelector, { id: "wallet", size: "30px", className: `${s.actionIcon} ${s.actionIconWallet}`, color: path === ROUTES.PROMOTIONS
                    ? isTg
                        ? 'var(--tg-theme-text-color)'
                        : '#fff'
                    : '#98989E', style: {
                    fill: path === ROUTES.PROMOTIONS
                        ? isTg
                            ? 'var(--tg-theme-text-color)'
                            : '#fff'
                        : '#98989E'
                } })),
            text: '',
            onClick: () => {
                navigate(ROUTES.PROMOTIONS);
            },
            tgStyles: { item: { opacity: path === ROUTES.PROMOTIONS ? '1' : '0.8' } }
        },
        {
            icon: (_jsx(IconSelector, { id: "user", size: "30px", className: `${s.actionIcon}`, color: path === ROUTES.PROFILE
                    ? isTg
                        ? 'var(--tg-theme-text-color)'
                        : '#fff'
                    : '#98989E' })),
            text: '',
            onClick: () => {
                navigate(ROUTES.PROFILE);
            },
            tgStyles: { item: { opacity: path === ROUTES.PROFILE ? '1' : '0.8' } }
        }
    ];
    return (_jsx(PageWrapper, { className: s.wrapper, headerClassName: s.wrapperHeader, containerWidth: "500px", tgStyles: wrapperTgStyles, header: _jsx(_Fragment, { children: showIntroduction || isError ? (_jsx(_Fragment, {})) : (_jsx(HeaderPanel, { title: "", containerWidth: "460px", variant: "black", className: s.header, tgStyles: headerStyles, actionLeft: _jsx(Link, { to: ROUTES.HOME, className: s.logo, children: _jsx(Text, { fontSize: "large", fontWeight: "bold", tgStyles: { color: 'var(--tg-theme-text-color)' }, children: "VeePN" }) }), actionRight: _jsx(_Fragment, { children: _jsx(TonConnectButton, {}) }) })) }), footer: _jsx(_Fragment, { children: showIntroduction || isError ? (_jsx(_Fragment, {})) : (_jsx(MobileMenu, { items: MobileMenuItems, borderRadius: "100px", className: s.actions, tgStyles: menuTgStyles })) }), pageTitle: "VeePN", content: _jsx("div", { className: s.wrapperContent, children: children }) }));
};
