import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-useless-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Text, Title, IconSelector, Div, AlertStatus } from '@delab-team/de-ui';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { ModalSearchList } from '../modal-search-list';
import { ModalInfoCard } from '../modal-info-card';
import { SkeletonInfo } from '../skeleton-info';
import { SvgSelector } from '../../assets/icons/svg-selector';
import { VPN } from '../../logic/vpn';
import { formatDataSize } from '../../utils/format-data-size';
import { copyToClipboard } from '../../utils/copy-to-clipboard';
import { openTelegramLink } from '../../utils/open-telegram-link';
import s from './connected-info.module.scss';
const infoTgStyles = { border: '1px solid var(--tg-theme-secondary-bg-color)' };
const infoTopTgStyles = { borderBottom: '1px solid var(--tg-theme-secondary-bg-color)' };
const infoDividerTgStyles = { background: 'var(--tg-theme-secondary-bg-color)' };
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
const alertModalTgStyles = {
    modal: { backgroundColor: 'var(--tg-theme-bg-color)' },
    modalButton: {
        background: 'var(--tg-theme-button-color)',
        color: 'var(--tg-theme-button-text-color)'
    },
    modalTitle: { color: 'var(--tg-theme-text-color)' }
};
export const ConnectedInfo = ({ user, keysData, isTg, userLoading }) => {
    const { t } = useTranslation();
    // Search list
    const [show, setShow] = useState(false);
    // Modal Search List Value
    const [value, setValue] = useState('');
    // Modal Detail
    const [showDetail, setShowDetail] = useState(false);
    // Connected Status State
    const [isConnected, setIsConnected] = useState(false);
    // Servers Data
    const [serverData, setServerData] = useState([]);
    // Servers Detail
    const [serverDetail, setServerDetail] = useState(undefined);
    // Connect Server
    const [connectServerData, setConnectServerData] = useState(undefined);
    // =================================================================
    // Active Server
    const [activeServer, setActiveServer] = useState({
        ip_user: '',
        country: '',
        isActive: false
    });
    const [loadingActiveServer, setLoadingActiveServer] = useState(false);
    // Loading
    const [isLoading, setIsLoading] = useState(false);
    const [isGeneratingConfig, setIsGeneratingConfig] = useState(false);
    const vpn = new VPN();
    useEffect(() => {
        vpn.getServers().then(res => setServerData(res));
    }, []);
    async function createKey() {
        if (!serverDetail?.id) {
            return;
        }
        if (!connectServerData) {
            try {
                const keyData = await vpn.getKey(serverDetail?.id);
                setConnectServerData(keyData);
            }
            catch (error) {
                console.error('Error fetching key:', error);
            }
        }
    }
    useEffect(() => {
        const connectServer = keysData?.find(el => el.id_server === serverDetail?.id);
        if (!connectServer) {
            createKey();
        }
        else {
            setConnectServerData(connectServer);
        }
    }, [showDetail]);
    // Close Modal
    const handleCloseModal = () => {
        setShow(false);
    };
    // Handle connect ModalInfoCard
    const handleConnectServer = async () => {
        if (!connectServerData) {
            console.error('connectServerData undefined');
            return;
        }
        openTelegramLink(connectServerData.key_data);
    };
    // Get active server
    useEffect(() => {
        const getActiveServer = async () => {
            try {
                setLoadingActiveServer(true);
                const res = await vpn.getActive();
                const ip = await vpn.getIpInfo(res.ip);
                const country = await ip.data.country;
                setActiveServer({
                    ...res,
                    country
                });
            }
            finally {
                setLoadingActiveServer(false);
            }
        };
        getActiveServer();
    }, []);
    function generateConfig() {
        setIsGeneratingConfig(true);
        setTimeout(() => {
            setIsGeneratingConfig(false);
            if (connectServerData?.key_data) {
                copyToClipboard(connectServerData.key_data);
                setTimeout(() => {
                    alert(t('home-page.connected-info.config-generated'));
                }, 500);
            }
        }, 200);
    }
    return (_jsxs(_Fragment, { children: [isConnected && (_jsx(AlertStatus, { classNameModal: s.alertModalStatus, tgStyles: alertModalTgStyles, classNameTitle: s.alertModalStatusTitle, iconStatus: _jsx(SvgSelector, { id: "success" }), buttonContent: t('common.ok'), statusTitle: t('common.successful'), isOpen: isConnected, onClose: () => setIsConnected(false) })), _jsx(ModalSearchList, { modalTitle: t('home-page.connected-info.servers'), modalData: serverData, searchValue: value, setValue: setValue, isOpen: show, onClose: handleCloseModal, setShowDetail: setShowDetail, setServerDetail: setServerDetail, setShow: setShow }), _jsx("div", { className: s.detailModal, children: showDetail && (_jsx(ModalInfoCard, { modalTitle: t('home-page.modal-info-card.title'), isOpen: showDetail, onClose: () => {
                        setShowDetail(false);
                        setServerDetail(undefined);
                        setConnectServerData(undefined);
                    }, modalTopTitle: serverDetail?.name_server, modalTopSubtitle: serverDetail?.ip || '', infoTitle: t('home-page.modal-info-card.workload-title'), infoSubtitle: `${serverDetail?.load_server}%`, serverTitle: t('home-page.modal-info-card.server-title'), serverSubtitle: serverDetail?.geo || '', handleConnect: handleConnectServer, connectServerData: connectServerData, isLoading: isLoading, generateConfig: generateConfig, isGeneratingConfig: isGeneratingConfig })) }), _jsxs(Div, { className: s.info, onClick: () => setShow(true), tgStyles: infoTgStyles, children: [_jsxs(Div, { className: s.infoTop, tgStyles: infoTopTgStyles, children: [_jsxs("div", { className: s.infoTopInner, children: [activeServer?.country && (_jsx(ReactCountryFlag, { countryCode: activeServer?.country, svg: true, style: {
                                            width: '2em',
                                            height: '2em'
                                        }, title: "US" })), _jsxs("div", { children: [_jsx(Title, { variant: "h2", className: s.infoTopTitle, tgStyles: textTgStyles, children: userLoading ? (_jsx(SkeletonInfo, { isTg: isTg, widthFull: true })) : (!activeServer?.isActive && t('home-page.connected-info.select-server')) }), _jsx(Text, { className: s.infoTopIp, children: userLoading ? (_jsx(SkeletonInfo, { isTg: isTg, widthHalf: true })) : (activeServer?.ip_user) })] })] }), _jsx("button", { className: s.infoTopAction, children: _jsx(IconSelector, { id: "chevron-right", size: "18px", color: "#fff", tgStyles: iconsTgStyles }) })] }), _jsxs("div", { className: s.infoStats, children: [_jsxs("div", { className: s.infoStatsItem, children: [_jsx(IconSelector, { id: "download", size: "26px", className: s.infoStatsIcon, color: "#3E88F7", tgStyles: iconsTgStyles }), _jsxs("div", { className: s.infoStatsDetail, children: [_jsx(Text, { className: s.infoStatsTitle, children: t('home-page.connected-info.used') }), _jsx(Text, { className: s.infoStatsSpeed, tgStyles: textTgStyles, children: userLoading ? (_jsx(SkeletonInfo, { isTg: isTg })) : (user?.infoUser?.used ? formatDataSize(user?.infoUser?.used) : '0 MB') })] })] }), _jsx(Div, { className: s.infoStatsDivider, tgStyles: infoDividerTgStyles }), _jsxs("div", { className: s.infoStatsItem, children: [_jsx(IconSelector, { id: "share-2", size: "26px", className: s.infoStatsIcon, color: "#3E88F7", tgStyles: iconsTgStyles }), _jsxs("div", { className: s.infoStatsDetail, children: [_jsx(Text, { className: s.infoStatsTitle, children: t('home-page.connected-info.limit') }), _jsx(Text, { className: s.infoStatsSpeed, tgStyles: textTgStyles, children: userLoading ? (_jsx(SkeletonInfo, { isTg: isTg })) : (user?.infoUser?.limit ? formatDataSize(user?.infoUser?.limit) : '0 MB') })] })] })] })] })] }));
};
