import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppInner } from '@delab-team/de-ui';
import { useTonAddress } from '@tonconnect/ui-react';
import { TonApi } from '@delab-team/ton-api-sdk';
// import { ProviderTonConnect } from '@delab-team/ton-network-react'
import { Home } from './pages/home';
import { Profile } from './pages/profile';
import { Promotions } from './pages/promotions';
import { Introduction } from './pages/introduction';
import { Download } from './pages/download';
import { SomethingWentWrong } from './pages/something-went-wrong';
import { Layout } from './layout';
import { ROUTES } from './utils/router';
import './utils/i18n';
// import { fixAmount } from './utils/fixAmount'
import { VPN } from './logic/vpn';
export const App = () => {
    const [firstRender, setFirstRender] = useState(false);
    const [isTg, setIsTg] = useState(false);
    const [balance, setBalance] = useState(undefined);
    // user
    const [user, setUser] = useState(undefined);
    const [userLoading, setUserLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    // user end
    const [keysData, setKeysData] = useState(undefined);
    // Introduction
    const [showIntroduction, setShowIntroduction] = useState(true);
    // Skipped introduction
    const [isSkippedIntroduction, setIsSkippedIntroduction] = useState(false);
    const rawAddress = useTonAddress();
    const api = new TonApi('AFJOFCU7XVPBKMIAAAAM6H4MQHHW57TAGBG2EY3C3A6USOZUSGXEI6MRDW32YKVQDZBCGUQ', 'mainnet');
    const vpn = new VPN();
    const navigate = useNavigate();
    // fetch keys data
    async function fetchData() {
        setUserLoading(true);
        const userData = await vpn.postAuth().finally(() => setUserLoading(false));
        setUser(userData);
        if (!userData) {
            setIsError(true);
            navigate(ROUTES.SOMETHING_WENT_WRONG);
        }
        else {
            setIsError(false);
        }
        const keysData = await vpn.getKeys();
        setKeysData(keysData);
    }
    async function loadUser(address) {
        const data = await api.Accounts.getHumanFriendlyInfo(address);
        if (!data || !data?.balance) {
            return undefined;
        }
        setBalance(data?.balance.toString());
        return true;
    }
    // load user
    useEffect(() => {
        if (rawAddress) {
            loadUser(rawAddress);
        }
    }, [rawAddress]);
    const TgObj = window.Telegram.WebApp;
    // init twa
    useEffect(() => {
        if (!firstRender && TgObj) {
            setFirstRender(true);
            const isTgCheck = window.Telegram.WebApp.initData !== '';
            const bodyStyle = document.body.style;
            if (window.location.pathname !== '/introduction') {
                TgObj.requestWriteAccess();
            }
            if (isTgCheck) {
                TgObj.ready();
                TgObj.enableClosingConfirmation();
                TgObj.expand();
                setIsTg(true);
                fetchData();
                bodyStyle.backgroundColor = 'var(--tg-theme-bg-color)';
                bodyStyle.setProperty('background-color', 'var(--tg-theme-bg-color)', 'important');
            }
        }
        vpn.getAutoKey();
    }, []);
    // introduction check
    useEffect(() => {
        const hasPassedIntroduction = localStorage.getItem('hasPassedIntroduction');
        if (hasPassedIntroduction) {
            setShowIntroduction(false);
        }
        else {
            navigate(ROUTES.INTRODUCTION);
        }
        // localStorage.removeItem('hasPassedIntroduction')
    }, []);
    // introduction skip check
    useEffect(() => {
        const hasSkippedIntroduction = localStorage.getItem('skippedIntroduction');
        if (hasSkippedIntroduction) {
            setIsSkippedIntroduction(true);
        }
    }, []);
    //= =======================================================================================================================================================
    const { i18n } = useTranslation();
    const savedLanguage = localStorage.getItem('i18nextLng');
    const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage || 'en');
    useEffect(() => {
        const initializeLanguage = async () => {
            const TgLanguage = window.Telegram.WebApp.initDataUnsafe.user.language_code;
            let language;
            const userDefinedLanguage = localStorage.getItem('i18nextLngOwn');
            if (userDefinedLanguage) {
                language = userDefinedLanguage;
            }
            else if (TgLanguage) {
                const lowerCaseTgLanguage = TgLanguage.toLowerCase();
                if (lowerCaseTgLanguage === 'ru' || lowerCaseTgLanguage === 'uk' || lowerCaseTgLanguage === 'en') {
                    language = lowerCaseTgLanguage;
                }
                else {
                    language = 'ru';
                }
            }
            else {
                language = 'ru';
            }
            setSelectedLanguage(language);
        };
        initializeLanguage();
    }, []);
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('i18nextLng', selectedLanguage);
    }, [selectedLanguage, i18n]);
    //= ========================================================================================================================================================
    useEffect(() => {
        if (window.location.pathname !== '/introduction') {
            TgObj.MainButton.hide();
            TgObj.BackButton.hide();
        }
    }, [window.location.pathname]);
    return _jsxs(AppInner, { isTg: isTg, children: [!showIntroduction && (_jsx(Layout, { showIntroduction: showIntroduction, isError: isError, isTg: isTg, children: _jsxs(Routes, { children: [_jsx(Route, { element: _jsx(Home, { isSkippedIntroduction: isSkippedIntroduction, userLoading: userLoading, user: user, keysData: keysData, isTg: isTg }), path: ROUTES.HOME }), _jsx(Route, { element: _jsx(Profile, { balance: balance, selectedLanguage: selectedLanguage, setSelectedLanguage: setSelectedLanguage, isTg: isTg }), path: ROUTES.PROFILE }), _jsx(Route, { element: _jsx(Promotions, { user: user, setUser: setUser, isTg: isTg, userLoading: userLoading }), path: ROUTES.PROMOTIONS }), _jsx(Route, { element: _jsx(Download, {}), path: ROUTES.DOWNLOAD }), _jsx(Route, { element: _jsx(Introduction, { rawAddress: rawAddress, user: user, keysData: keysData, isTg: isTg, setShowIntroduction: setShowIntroduction }), path: ROUTES.INTRODUCTION }), _jsx(Route, { element: _jsx(SomethingWentWrong, {}), path: ROUTES.SOMETHING_WENT_WRONG }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) })), showIntroduction && (_jsxs(Routes, { children: [_jsx(Route, { element: _jsx(Introduction, { rawAddress: rawAddress, user: user, keysData: keysData, isTg: isTg, setShowIntroduction: setShowIntroduction }), path: ROUTES.INTRODUCTION }), _jsx(Route, { element: _jsx(SomethingWentWrong, {}), path: ROUTES.SOMETHING_WENT_WRONG }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }))] });
};
