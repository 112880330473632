import { beginCell, contractAddress, SendMode } from '@ton/core';
export function jettonMinterConfigToCell(config) {
    return beginCell()
        .storeCoins(0)
        .storeAddress(config.admin)
        .storeRef(config.content)
        .storeRef(config.walletCode)
        .endCell();
}
export class JettonMinter {
    constructor(address, init) {
        this.address = address;
        this.init = init;
    }
    static createFromAddress(address) {
        return new JettonMinter(address);
    }
    static createFromConfig(config, code, workchain = 0) {
        const data = jettonMinterConfigToCell(config);
        const init = { code, data };
        return new JettonMinter(contractAddress(workchain, init), init);
    }
    async sendDeploy(provider, via, value) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().endCell(),
        });
    }
    async sendMint(provider, via, value, forwardValue, recipient, amount) {
        await provider.internal(via, {
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell()
                .storeUint(21, 32)
                .storeUint(0, 64)
                .storeAddress(recipient)
                .storeCoins(forwardValue)
                .storeRef(beginCell()
                .storeUint(0x178d4519, 32)
                .storeUint(0, 64)
                .storeCoins(amount)
                .storeAddress(this.address)
                .storeAddress(this.address)
                .storeCoins(0)
                .storeUint(0, 1)
                .endCell())
                .endCell(),
            value: value + forwardValue,
        });
    }
    async getWalletAddressOf(provider, address) {
        return (await provider.get('get_wallet_address', [
            { type: 'slice', cell: beginCell().storeAddress(address).endCell() },
        ])).stack.readAddress();
    }
    async getWalletCode(provider) {
        let stack = (await provider.get('get_jetton_data', [])).stack;
        stack.skip(4);
        return stack.readCell();
    }
}
