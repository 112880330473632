import { jsx as _jsx } from "react/jsx-runtime";
import IOS from '../assets/icons/categories/ios.svg';
import MACOS from '../assets/icons/categories/macOS.svg';
import WINDOWS from '../assets/icons/categories/windows.svg';
import LINUX from '../assets/icons/categories/linux.svg';
import ANDROID from '../assets/icons/categories/android.svg';
export const DownloadData = [
    {
        name: 'IOS',
        link: 'https://itunes.apple.com/app/outline-app/id1356177741',
        icon: _jsx(IOS, {})
    },
    {
        name: 'macOS',
        link: 'https://itunes.apple.com/app/outline-app/id1356178125',
        icon: _jsx(MACOS, {})
    },
    {
        name: 'Windows',
        link: 'https://s3.amazonaws.com/outline-releases/client/windows/stable/Outline-Client.exe',
        icon: _jsx(WINDOWS, {})
    },
    {
        name: 'Linux',
        link: 'https://s3.amazonaws.com/outline-releases/client/linux/stable/Outline-Client.AppImage',
        icon: _jsx(LINUX, {})
    },
    {
        name: 'Android',
        link: 'https://play.google.com/store/apps/details?id=org.outline.android.client',
        icon: _jsx(ANDROID, {})
    }
];
