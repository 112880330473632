import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { Button, Text, Title } from '@delab-team/de-ui';
import { VPN } from '../../../../logic/vpn';
import { PromotionPay } from '../../../../components/promotion-pay';
import * as planSticker from '../../../../assets/stickers/plan.json';
import s from './second-step.module.scss';
export const SecondStep = ({ activeRate, user, currentStep, setCurrentStep, handlePrevStep, setActiveRate, handleNextStep, isTg }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: planSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    // Rates Data
    const [ratesData, setRatesData] = useState([]);
    // Loading Rates Data
    const [ratesLoading, setRatesLoading] = useState(false);
    // Activate Free
    const [activateError, setActivateError] = useState(false);
    const [loadingRate, setLoadingRate] = useState(false);
    const vpn = new VPN();
    const TgObj = window.Telegram.WebApp;
    function enableBtn2(text) {
        TgObj.BackButton.show();
        TgObj.MainButton.enable();
        TgObj.MainButton.show();
        if (activeRate) {
            TgObj.MainButton.text = text;
            TgObj.MainButton.color = '#40a7e3';
        }
        else {
            TgObj.MainButton.text = 'Select your plan';
            TgObj.MainButton.color = '#9a9c9a';
            TgObj.MainButton.disable();
        }
    }
    useEffect(() => {
        if (currentStep !== 2) {
            return;
        }
        enableBtn2(t('common.next-btn'));
        const handleMainButtonClick = async () => {
            const price = activeRate?.price;
            if (price === 0) {
                try {
                    const TgObj = window.Telegram.WebApp;
                    TgObj.MainButton.color = '#9a9c9a';
                    TgObj.MainButton.disable();
                    const activationResult = await vpn.activateFree();
                    if (activationResult) {
                        TgObj.showAlert(t('common.congratulations'));
                        localStorage.setItem('currentIntroductionStep', '4');
                        localStorage.removeItem('skippedIntroduction');
                        window.location.href = '/introduction';
                    }
                    else {
                        TgObj.showAlert(t('common.used-subscription'));
                    }
                }
                finally {
                    TgObj.MainButton.color = '#40a7e3';
                    TgObj.MainButton.enable();
                }
            }
            else {
                localStorage.setItem('currentIntroductionStep', '3');
                localStorage.removeItem('skippedIntroduction');
                window.location.href = '/introduction';
            }
        };
        TgObj.MainButton.onClick(handleMainButtonClick);
        TgObj.BackButton.onClick(() => handlePrevStep());
        return () => {
            TgObj.MainButton.offClick(handleMainButtonClick);
        };
    }, [activeRate, setActiveRate, currentStep === 2]);
    useEffect(() => {
        const fetchRates = async () => {
            try {
                setRatesLoading(true);
                const res = await vpn.getRates();
                setRatesData(res);
            }
            finally {
                setRatesLoading(false);
            }
        };
        fetchRates();
    }, []);
    const sortedDataByPrice = ratesData?.slice()?.sort((a, b) => Number(a?.price) - Number(b?.price));
    const handleSkipIntroduction = () => {
        localStorage.setItem('hasPassedIntroduction', 'true');
        localStorage.setItem('currentIntroductionStep', '2');
        localStorage.setItem('skippedIntroduction', 'true');
        window.location.pathname = '/';
    };
    return (_jsxs("div", { className: s.secondStep, children: [_jsx("div", { className: s.secondStepImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx("div", { className: s.secondStepTop, children: _jsx(Title, { variant: "h2", className: s.secondStepTitle, children: t('introduction-page.second-step.title') }) }), _jsx(Text, { className: s.secondStepDescription, children: t('introduction-page.second-step.description') }), _jsx(PromotionPay, { isTg: isTg, ratesLoading: ratesLoading, activeRate: activeRate, setActiveRate: setActiveRate, data: sortedDataByPrice, showTitle: false, loadingRate: loadingRate }), _jsx(Button, { className: s.skip, onClick: () => handleSkipIntroduction(), children: t('common.skip') })] }));
};
