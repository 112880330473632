import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { Text, Title } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import VPN from '../../../../assets/icons/advantages/vpn.svg';
import STARS from '../../../../assets/icons/advantages/stars.svg';
import PRIVACY from '../../../../assets/icons/advantages/privacy.svg';
import * as anonimSticker from '../../../../assets/stickers/anonim.json';
import s from './first-step.module.scss';
export const FirstStep = ({ handleNextStep, currentStep }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: anonimSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const TgObj = window.Telegram.WebApp;
    const firstStep = currentStep === 1;
    function enableBtn1(text, handleNextStep) {
        TgObj.MainButton.show();
        TgObj.MainButton.enable();
        TgObj.MainButton.text = text;
        TgObj.MainButton.color = '#40a7e3';
        TgObj.MainButton.onClick(() => handleNextStep());
    }
    useEffect(() => {
        enableBtn1(t('common.next-btn'), handleNextStep);
    }, [firstStep]);
    return (_jsxs("div", { className: s.firstStep, children: [_jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }), _jsx(Title, { variant: "h1", className: s.firstStepTitle, children: "VeePN" }), _jsx(Text, { className: s.firstStepDescription, children: t('introduction-page.first-step.title') }), _jsxs("div", { className: s.firstStepAdvantages, children: [_jsxs("div", { className: s.stepAdvantages, children: [_jsx("div", { className: s.stepAdvantagesImg, children: _jsx(VPN, {}) }), _jsxs("div", { className: s.stepAdvantagesInfo, children: [_jsx(Title, { variant: "h3", className: s.stepAdvantagesTitle, children: t('introduction-page.first-step.item1.title') }), _jsx(Text, { className: s.stepAdvantagesText, children: t('introduction-page.first-step.item1.text') })] })] }), _jsxs("div", { className: s.stepAdvantages, children: [_jsx("div", { className: s.stepAdvantagesImg, children: _jsx(STARS, {}) }), _jsxs("div", { className: s.stepAdvantagesInfo, children: [_jsx(Title, { variant: "h3", className: s.stepAdvantagesTitle, children: t('introduction-page.first-step.item2.title') }), _jsx(Text, { className: s.stepAdvantagesText, children: t('introduction-page.first-step.item2.text') })] })] }), _jsxs("div", { className: s.stepAdvantages, children: [_jsx("div", { className: s.stepAdvantagesImg, children: _jsx(PRIVACY, {}) }), _jsxs("div", { className: s.stepAdvantagesInfo, children: [_jsx(Title, { variant: "h3", className: s.stepAdvantagesTitle, children: t('introduction-page.first-step.item3.title') }), _jsx(Text, { className: s.stepAdvantagesText, children: t('introduction-page.first-step.item3.text') })] })] })] })] }));
};
