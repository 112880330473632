// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e6ukFzQEmthfjNvJKsIk{display:flex;align-items:center;justify-content:center;flex-direction:column}.wNwdAou4IMRoXkq7OtvM{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.WyqwLCl09WOEr9mA7ZNZ{font-size:21px;font-weight:500;line-height:120%;max-width:250px;margin:0 auto;text-align:center;color:var(--tg-theme-text-color)}`, "",{"version":3,"sources":["webpack://./src/pages/something-went-wrong/styles.module.scss"],"names":[],"mappings":"AAAA,sBAEE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA,CACA,gCAAA","sourcesContent":[".inner {\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Title {\n    font-size: 21px;\n    font-weight: 500;\n    line-height: 120%;\n    max-width: 250px;\n    margin: 0 auto;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `e6ukFzQEmthfjNvJKsIk`,
	"innerImg": `wNwdAou4IMRoXkq7OtvM`,
	"innerTitle": `WyqwLCl09WOEr9mA7ZNZ`
};
export default ___CSS_LOADER_EXPORT___;
