import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const SvgSelector = ({ id }) => {
    switch (id) {
        case 'success':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", children: [_jsx("circle", { cx: "12.5", cy: "12.5", r: "12", stroke: "#1AB233" }), _jsx("path", { d: "M16.8333 9.79169L10.875 15.75L8.16663 13.0417", stroke: "#1AB233", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }));
        case 'failed':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", children: [_jsx("path", { d: "M10.4933 9.38306L15.8734 14.7453", stroke: "#FF3939", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.4933 14.7454L15.8734 9.38314", stroke: "#FF3939", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M25.0433 12.5C25.0433 19.1258 19.6536 24.5 13.0016 24.5C6.34963 24.5 0.959961 19.1258 0.959961 12.5C0.959961 5.87415 6.34963 0.5 13.0016 0.5C19.6536 0.5 25.0433 5.87415 25.0433 12.5Z", stroke: "#FF3939" })] }));
        case 'ton':
            return (_jsxs("svg", { width: "15", height: "15", viewBox: "0 0 15 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { id: "ton_symbol (1) 1", clipPath: "url(#clip0_270_1134)", children: [_jsx("path", { id: "Vector", d: "M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z", fill: "#0098EA" }), _jsx("path", { id: "Vector_2", d: "M10.0608 4.18604H4.9389C3.99717 4.18604 3.40028 5.20188 3.87407 6.02311L7.0351 11.5021C7.24138 11.8598 7.75832 11.8598 7.96459 11.5021L11.1263 6.02311C11.5994 5.2032 11.0025 4.18604 10.0614 4.18604H10.0608ZM7.03253 9.85901L6.34411 8.52666L4.68302 5.55578C4.57344 5.36562 4.70879 5.12195 4.93826 5.12195H7.03189V9.85965L7.03253 9.85901ZM10.3154 5.55513L8.65494 8.5273L7.96652 9.85901V5.12131H10.0601C10.2896 5.12131 10.425 5.36498 10.3154 5.55513Z", fill: "white" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_270_1134", children: _jsx("rect", { width: "15", height: "15", fill: "white" }) }) })] }));
        case 'wallet':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "26", height: "24", viewBox: "0 0 26 24", fill: "none", children: [_jsx("g", { clipPath: "url(#clip0_0_379)", children: _jsx("path", { d: "M4.46875 0C3.21615 0.0357143 2.16667 0.5 1.32031 1.39286C0.473958 2.28571 0.0338542 3.39286 0 4.71429V19.2857C0.0338542 20.6071 0.473958 21.7143 1.32031 22.6071C2.16667 23.5 3.21615 23.9643 4.46875 24H21.5312C22.7839 23.9643 23.8333 23.5 24.6797 22.6071C25.526 21.7143 25.9661 20.6071 26 19.2857V9.85714C25.9661 8.53571 25.526 7.42857 24.6797 6.53571C23.8333 5.64286 22.7839 5.17857 21.5312 5.14286H6.09375C5.34896 5.21429 4.94271 5.64286 4.875 6.42857C4.94271 7.21429 5.34896 7.64286 6.09375 7.71429H21.5312C22.1068 7.71429 22.5807 7.92857 22.9531 8.35714C23.3594 8.75 23.5625 9.25 23.5625 9.85714V19.2857C23.5625 19.8929 23.3594 20.3929 22.9531 20.7857C22.5807 21.2143 22.1068 21.4286 21.5312 21.4286H4.46875C3.89323 21.4286 3.41927 21.2143 3.04688 20.7857C2.64062 20.3929 2.4375 19.8929 2.4375 19.2857V4.71429C2.4375 4.10714 2.64062 3.60714 3.04688 3.21429C3.41927 2.78571 3.89323 2.57143 4.46875 2.57143H23.1562C23.901 2.5 24.3073 2.07143 24.375 1.28571C24.3073 0.5 23.901 0.0714286 23.1562 0H4.46875ZM19.5 16.2857C19.974 16.2857 20.3633 16.125 20.668 15.8036C20.9727 15.4821 21.125 15.0714 21.125 14.5714C21.125 14.0714 20.9727 13.6607 20.668 13.3393C20.3633 13.0179 19.974 12.8571 19.5 12.8571C19.026 12.8571 18.6367 13.0179 18.332 13.3393C18.0273 13.6607 17.875 14.0714 17.875 14.5714C17.875 15.0714 18.0273 15.4821 18.332 15.8036C18.6367 16.125 19.026 16.2857 19.5 16.2857Z", fill: "#98989E" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_0_379", children: _jsx("rect", { width: "26", height: "24", fill: "white" }) }) })] }));
        default:
            return null;
    }
};
