import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { VPN } from '../../../../logic/vpn';
import { ServerInfo } from './server-info';
import { copyToClipboard } from '../../../../utils/copy-to-clipboard';
import { openTelegramLink } from '../../../../utils/open-telegram-link';
export const FifthStep = ({ handleNextStep, isTg, setCurrentStep, currentStep }) => {
    const [serverData, setServerData] = useState(undefined);
    const [isServerLoading, setIsServerLoading] = useState(true);
    const [telegramLinkOpened, setTelegramLinkOpened] = useState(false);
    const [isGeneratingConfig, setIsGeneratingConfig] = useState(false);
    const vpn = new VPN();
    const { t } = useTranslation();
    const TgObj = window.Telegram.WebApp;
    const openTelegramLinkOnce = useCallback((keyData) => {
        if (!telegramLinkOpened) {
            openTelegramLink(keyData);
            setTelegramLinkOpened(true);
        }
    }, [telegramLinkOpened]);
    const fifthStep = currentStep === 5;
    const handleConnect = () => {
        if (!serverData?.key?.key_data) {
            console.error('connectServerData undefined');
            return;
        }
        openTelegramLinkOnce(serverData?.key?.key_data);
        handleNextStep();
    };
    function generateConfig() {
        setIsGeneratingConfig(true);
        setTimeout(() => {
            setIsGeneratingConfig(false);
            if (serverData?.key?.key_data) {
                copyToClipboard(serverData?.key?.key_data);
                setTimeout(() => {
                    alert(t('introduction-page.fifth-step.alert'));
                    handleNextStep();
                }, 500);
            }
        }, 200);
    }
    //= =======================================================================================================================================================
    function setBackButton(serverData) {
        if (!serverData) {
            TgObj.BackButton.onClick(() => {
                localStorage.setItem('currentIntroductionStep', '5');
                setCurrentStep(5);
            });
        }
    }
    function enableBtn5() {
        TgObj.BackButton.show();
        TgObj.MainButton.show();
        if (!serverData || !serverData?.key?.key_data) {
            TgObj.MainButton.color = '#9a9c9a';
            TgObj.MainButton.disable();
        }
        else {
            TgObj.MainButton.enable();
            TgObj.MainButton.onClick(handleConnect);
            TgObj.MainButton.color = '#40a7e3';
        }
        TgObj.MainButton.text = t('common.connect-btn');
    }
    async function fetchServerData() {
        const serverData = await vpn.getAutoKey().finally(() => setIsServerLoading(false));
        setServerData(serverData);
    }
    useEffect(() => {
        if (!serverData) {
            fetchServerData();
        }
        enableBtn5();
        setBackButton(serverData);
        return () => {
            TgObj.MainButton.offClick(handleConnect);
        };
    }, [fifthStep, serverData]);
    return (_jsx(_Fragment, { children: _jsx(ServerInfo, { serverName: serverData?.server?.name_server, serverIp: serverData?.server?.ip || '', infoTitle: t('home-page.modal-info-card.workload-title'), serverTitle: t('home-page.modal-info-card.server-title'), serverGeo: serverData?.server?.geo || '', infoLoadServer: `${serverData?.server?.load_server}%`, generateConfig: generateConfig, isServerLoading: isServerLoading, isGeneratingConfig: isGeneratingConfig, serverKeyData: serverData?.key?.key_data, isTg: isTg }) }));
};
