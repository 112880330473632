import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FirstStep } from './introduction-steps/first-step';
import { SecondStep } from './introduction-steps/second-step';
import { FourthStep } from './introduction-steps/fourth-step';
import { ThirdStep } from './introduction-steps/third-step';
import { FifthStep } from './introduction-steps/fifth-step';
import { SixthStep } from './introduction-steps/sixth-step';
import s from './introduction.module.scss';
export const Introduction = ({ setShowIntroduction, isTg, user, rawAddress }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [payToken, setPayToken] = useState(undefined);
    // Active Rate
    const [activeRate, setActiveRate] = useState(undefined);
    const location = useLocation();
    useEffect(() => {
        const storedActiveRate = localStorage.getItem('activeRate');
        if (storedActiveRate) {
            setActiveRate(JSON.parse(storedActiveRate));
        }
    }, []);
    // Introduction step
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const stepParam = urlSearchParams.get('step');
        const step = stepParam
            ? parseInt(stepParam, 10)
            : parseInt(localStorage.getItem('currentIntroductionStep') || '1', 10);
        if (!isNaN(step) && step >= 1 && step <= 6) {
            setCurrentStep(step);
            setShowIntroduction(true);
        }
    }, [location.search, setShowIntroduction]);
    useEffect(() => {
        localStorage.setItem('currentIntroductionStep', currentStep.toString());
    }, [currentStep]);
    // active rate
    useEffect(() => {
        if (activeRate) {
            localStorage.setItem('activeRate', JSON.stringify(activeRate));
        }
    }, [activeRate]);
    useEffect(() => {
        const storedActiveRate = localStorage.getItem('activeRate');
        if (storedActiveRate) {
            setActiveRate(JSON.parse(storedActiveRate));
        }
    }, []);
    const handleIntroductionClose = () => {
        localStorage.setItem('hasPassedIntroduction', 'true');
        localStorage.removeItem('currentIntroductionStep');
        localStorage.removeItem('activeRate');
        localStorage.removeItem('skippedIntroduction');
        setShowIntroduction(false);
        setCurrentStep(1);
        window.location.pathname = '/';
    };
    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };
    const handlePrevStep = () => {
        setCurrentStep(currentStep - 1);
    };
    return (_jsx("div", { className: s.wrapper, children: _jsxs("div", { className: s.content, children: [currentStep === 1 && _jsx(FirstStep, { currentStep: currentStep, handleNextStep: handleNextStep }), currentStep === 2 && (_jsx(SecondStep, { setCurrentStep: setCurrentStep, activeRate: activeRate, currentStep: currentStep, setActiveRate: setActiveRate, handlePrevStep: handlePrevStep, handleNextStep: handleNextStep, user: user, isTg: isTg })), currentStep === 3 && (_jsx(ThirdStep, { handlePrevStep: handlePrevStep, amount: activeRate?.price, activePayToken: payToken, handleNextStep: handleNextStep, currentStep: currentStep, rawAddress: rawAddress, user: user })), currentStep === 4 && (_jsx(FourthStep, { handleNextStep: handleNextStep, currentStep: currentStep })), currentStep === 5 && (_jsx(FifthStep, { handleNextStep: handleNextStep, setCurrentStep: setCurrentStep, currentStep: currentStep, isTg: isTg })), currentStep === 6 && (_jsx(SixthStep, { handleIntroductionClose: handleIntroductionClose, currentStep: currentStep }))] }) }));
};
