import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { Text, Title } from '@delab-team/de-ui';
import * as successSticker from '../../../../assets/stickers/success.json';
import s from './sixth-step.module.scss';
export const SixthStep = ({ handleIntroductionClose, currentStep }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: successSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const TgObj = window.Telegram.WebApp;
    const sixthStep = currentStep === 6;
    function enableBtn6(text, handleNextStep) {
        TgObj.MainButton.show();
        TgObj.MainButton.enable();
        TgObj.MainButton.text = text;
        TgObj.MainButton.color = '#40a7e3';
        TgObj.MainButton.onClick(() => handleNextStep());
        TgObj.BackButton.hide();
    }
    const handleClose = () => {
        TgObj.MainButton.hide();
        handleIntroductionClose();
    };
    useEffect(() => {
        enableBtn6(t('common.finish-btn'), handleClose);
    }, [sixthStep]);
    return (_jsx("div", { className: s.sixthStep, children: _jsxs("div", { className: s.sixthStepInner, children: [_jsx("div", { className: s.sixthStepImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx(Title, { variant: "h2", className: s.sixthStepTitle, children: t('introduction-page.sixth-step.title') }), _jsx(Text, { className: s.sixthStepDescription, children: t('introduction-page.sixth-step.description') })] }) }));
};
