import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Cell, Div, HeaderSearch, IconSelector, Modal, Text } from '@delab-team/de-ui';
import ReactCountryFlag from 'react-country-flag';
import ActiveIcon from '../../assets/img/active-icon.svg';
import s from './modal-search-list.module.scss';
const modalTgStyles = { backgroundColor: 'var(--tg-theme-bg-color)' };
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
const modalDetailStyles = { backgroundColor: 'var(--tg-theme-secondary-bg-color)' };
const searchTgStyles = {
    backgroundColor: 'var(--tg-theme-secondary-bg-color)',
    color: 'var(--tg-theme-text-color)'
};
export const ModalSearchList = ({ modalTitle, searchValue, setValue, modalData, onClose, isOpen, setShowDetail, setServerDetail, setShow }) => {
    const { t } = useTranslation();
    const handleCell = (el) => {
        setShowDetail(true);
        setShow(false);
        setServerDetail(el);
    };
    const [searchData, setSearchData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const filteredData = modalData?.filter(item => item?.name_server.toLowerCase().includes(searchValue.toLowerCase()));
            setSearchData(filteredData);
        };
        fetchData();
    }, [modalData, searchValue]);
    return (_jsx("div", { className: s.wrapper, children: _jsxs(Modal, { isOpen: isOpen, onClose: onClose, isCloseButton: false, className: s.modal, tgStyles: { modalContent: modalTgStyles }, children: [_jsxs("div", { className: s.modalTop, children: [_jsx(Button, { className: s.modalTopButton, onClick: onClose, tgStyles: { color: 'var(--tg-theme-link-color)' }, children: t('common.cancel') }), _jsx(Text, { className: s.modalTitle, tgStyles: textTgStyles, children: modalTitle })] }), _jsx(HeaderSearch, { className: s.modalSearch, value: searchValue, onChange: (e) => setValue(e.target.value), tgStyles: {
                        searchBody: searchTgStyles,
                        input: textTgStyles,
                        icon: 'var(--tg-theme-link-color)'
                    }, variant: "black" }), _jsx(Div, { className: s.modalList, tgStyles: modalDetailStyles, children: searchData.length !== 0 ? searchData.map(el => (_jsx(Cell, { onClick: () => handleCell(el), className: s.modalListItem, leftContent: _jsx(ReactCountryFlag, { countryCode: el.geo, svg: true, style: {
                                width: '2em',
                                height: '2em'
                            }, title: "US" }), rightContent: _jsx(IconSelector, { id: "chevron-right", color: "#fff", size: "18px", tgStyles: iconsTgStyles }), centerContent: _jsxs("div", { className: s.modalListItemInfo, children: [_jsx(Text, { className: s.modalListItemTitle, tgStyles: textTgStyles, children: el.name_server }), el.active && (_jsx("div", { className: s.modalListItemArrow, children: _jsx(ActiveIcon, {}) }))] }), rowContentClassName: s.modalListItemContent, rowLeftClassName: s.modalListItemInfo }, el.id))) : _jsx(Text, { className: s.notFound, tgStyles: textTgStyles, children: t('common.not-found') }) })] }) }));
};
