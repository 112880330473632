import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useState } from 'react';
import { Button, Div, IconSelector, Li, Title, Text } from '@delab-team/de-ui';
import { v1 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { SkeletonInfo } from '../skeleton-info';
import s from './promotion-pay.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
const buttonTgStyles = {
    background: 'var(--tg-theme-button-color)',
    color: 'var(--tg-theme-button-text-color)'
};
export const PromotionPay = ({ isTg, data, activeRate, setActiveRate, ratesLoading, setShow, showPayModal = false, showTitle = true, loadingRate }) => {
    const { t } = useTranslation();
    const [isSelected, setIsSelected] = useState(false);
    const rawAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    return (_jsxs("div", { className: s.promotion, children: [showTitle && (_jsx(Title, { variant: "h3", className: s.promotionTitle, tgStyles: textTgStyles, children: t('promotions-page.promotion-pay.title') })), _jsx("ul", { className: s.promotionList, children: ratesLoading ? new Array(3).fill(null).map(_ => _jsx(Div, { className: `${s.promotionLi}`, tgStyles: {
                        border: '1px solid var(--tg-theme-secondary-bg-color)',
                        background: 'transparent'
                    }, children: _jsxs("div", { className: s.promotionLiAction, children: [_jsx(Div, { className: `${s.promotionLiButton}`, tgStyles: { border: '1px solid var(--tg-theme-text-color)' } }), _jsxs("div", { className: s.promotionLiBody, children: [_jsxs("div", { className: s.promotionLiLeft, children: [_jsx("div", { className: s.promotionLiInfo, children: _jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: _jsx(SkeletonInfo, { isTg: isTg }) }) }), _jsxs(Text, { className: s.promotionLiDescription, tgStyles: textTgStyles, children: [_jsx(SkeletonInfo, { isTg: isTg }), " ", ' ', " ", _jsx(SkeletonInfo, { isTg: isTg })] })] }), _jsx("div", { className: s.promotionLiRight, children: _jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: _jsx(SkeletonInfo, { isTg: isTg }) }) })] })] }) }, v1())) : data.map(el => (_jsx(Li, { className: `${s.promotionLi} ${activeRate?.id === el?.id ? s.promotionLiActive : ''}`, tgStyles: {
                        border: activeRate?.id === el?.id ? '1px solid var(--tg-theme-button-color)' : '1px solid var(--tg-theme-secondary-bg-color)',
                        backgroundColor: activeRate?.id === el?.id ? 'var(--tg-theme-secondary-bg-color)' : 'transparent'
                    }, onClick: () => {
                        setActiveRate(el);
                        localStorage.setItem('activeRate', JSON.stringify(el));
                        setIsSelected(!isSelected);
                    }, children: _jsxs("div", { className: s.promotionLiAction, children: [_jsx(Div, { className: `${s.promotionLiButton} ${activeRate?.id === el?.id ? s.promotionLiButtonActive : ''}`, onClick: () => {
                                    setActiveRate(el);
                                    localStorage.setItem('activeRate', JSON.stringify(el));
                                    setIsSelected(!isSelected);
                                }, tgStyles: { border: activeRate?.id === el?.id ? '1px solid var(--tg-theme-button-color)' : '1px solid var(--tg-theme-text-color)' }, children: activeRate?.id === el?.id ? (_jsx(IconSelector, { id: "check", size: "18px", color: "#1C77FF", tgStyles: iconsTgStyles })) : null }), _jsxs("div", { className: s.promotionLiBody, children: [_jsxs("div", { className: s.promotionLiLeft, children: [_jsxs("div", { className: s.promotionLiInfo, children: [_jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: el?.name_rate }), el.price_old !== 0 && (_jsxs("div", { className: s.promotionLiBadge, children: ["-", Math.round(((el.price_old - el.price) / el.price_old) * 100), "%"] }))] }), _jsxs(Text, { className: s.promotionLiDescription, tgStyles: textTgStyles, children: [el?.price_old !== 0 && _jsxs("span", { className: s.promotionLiOldPrice, children: ["$", el?.price_old] }), " ", ' ', " $", el?.price, " / ", el?.name_rate] })] }), _jsx("div", { className: s.promotionLiRight, children: _jsx(Text, { className: s.promotionLiTitle, tgStyles: textTgStyles, children: el.count_days === 7 ? 'Free / 7 days' : _jsxs(_Fragment, { children: ["$", el?.price_m, " / month"] }) }) })] })] }) }, v1()))) }), showPayModal && setShow && (_jsx(Button, { className: s.promotionActionPay, onClick: () => {
                    localStorage.removeItem('hasPassedIntroduction');
                    localStorage.setItem('toPaymentPage', 'true');
                    localStorage.setItem('currentIntroductionStep', '3');
                    window.location.href = '/introduction';
                }, tgStyles: buttonTgStyles, disabled: !activeRate || loadingRate, children: t('common.pay-btn') }))] }));
};
