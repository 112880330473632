import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { IconSelector, Li, Text } from '@delab-team/de-ui';
import { DownloadData } from '../../mocks/download-data';
import s from './download-modal.module.scss';
const textTgStyles = { color: 'var(--tg-theme-text-color)' };
const iconsTgStyles = { stroke: 'var(--tg-theme-link-color)' };
const downloadItemTgStyles = { border: '1px solid var(--tg-theme-secondary-bg-color)' };
export const DownloadModal = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let filteredData;
    if (/android/i.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Android');
    }
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        filteredData = DownloadData.find(el => el.name === 'IOS');
    }
    else if (/Mac/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'macOS');
    }
    else if (/Win/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Windows');
    }
    else if (/Linux/.test(userAgent)) {
        filteredData = DownloadData.find(el => el.name === 'Linux');
    }
    else {
        console.log('The operating system could not be determined.');
    }
    return (_jsx("ul", { className: s.downloadList, children: DownloadData.map(item => (_jsx(Li, { className: `${s.downloadItem} ${item === filteredData ? s.selected : ''}`, tgStyles: downloadItemTgStyles, children: _jsxs(Link, { to: item.link || '', children: [_jsxs("div", { className: s.downloadItemInfo, children: [item.icon, _jsx(Text, { className: s.downloadItemName, tgStyles: textTgStyles, children: item.name })] }), _jsx(IconSelector, { id: "download", color: "#fff", size: "25px", tgStyles: iconsTgStyles })] }) }, item.name))) }));
};
