// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bv2hbjbiIdYXDGNVfMUE{position:relative;z-index:150}.U3U9BM8cv1T13pi5Kypq{padding:10px;border-radius:10px;border:1px solid #3d3d3d}.U3U9BM8cv1T13pi5Kypq:not(:last-child){margin-bottom:10px}.U3U9BM8cv1T13pi5Kypq:hover{border:1px solid #636262}.U3U9BM8cv1T13pi5Kypq>a{display:flex;align-items:center;justify-content:space-between;gap:10px}.QJQAtcT2GMlSNfRGWa7Z{display:flex;align-items:center;gap:8px}.QJQAtcT2GMlSNfRGWa7Z>svg{height:25px;width:25px}.QJQAtcT2GMlSNfRGWa7Z>svg>path{fill:var(--tg-theme-link-color)}.P68NyX8GNa0wZWynhFAR{border:1px solid var(--tg-theme-button-color) !important}`, "",{"version":3,"sources":["webpack://./src/components/download-modal/download-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,uCACE,kBAAA,CAEF,4BACE,wBAAA,CAEF,wBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAEF,sBAQE,YAAA,CACA,kBAAA,CACA,OAAA,CATA,0BACE,WAAA,CACA,UAAA,CACA,+BACE,+BAAA,CASR,sBACE,wDAAA","sourcesContent":[".wrapper {\n  position: relative;\n  z-index: 150;\n}\n\n.downloadItem {\n  padding: 10px;\n  border-radius: 10px;\n  border: 1px solid #3D3D3D;\n  &:not(:last-child) {\n    margin-bottom: 10px;\n  }\n  &:hover {\n    border: 1px solid #636262;\n  }\n  > a {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 10px;\n  }\n  &Info {\n    > svg {\n      height: 25px;\n      width: 25px;\n      > path {\n        fill: var(--tg-theme-link-color)\n      }\n    }\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n\n.selected {\n  border: 1px solid var(--tg-theme-button-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `bv2hbjbiIdYXDGNVfMUE`,
	"downloadItem": `U3U9BM8cv1T13pi5Kypq`,
	"downloadItemInfo": `QJQAtcT2GMlSNfRGWa7Z`,
	"selected": `P68NyX8GNa0wZWynhFAR`
};
export default ___CSS_LOADER_EXPORT___;
