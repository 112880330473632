import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import ReactDOM from 'react-dom/client';
import eruda from 'eruda';
import { BrowserRouter } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { App } from './App';
import './index.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
const el = document.createElement('div');
document.body.appendChild(el);
eruda.init({
    container: el,
    tool: ['console', 'elements']
});
root.render(_jsx(TonConnectUIProvider, { manifestUrl: "https://gist.github.com/anovic123/6b35742c9f28ae852810f72ac62973d9.txt", children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
