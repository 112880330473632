// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PYvW_1vgNwuFXWIzWCQT{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.bOESl9fKkXJx45PKzNAX{display:flex;align-items:center;justify-content:center;gap:10px;margin-bottom:5px}.SSic_ra0P5N_jGVNXzvP{font-size:21px;font-weight:500;text-align:center;color:var(--tg-theme-text-color)}.dsOf8Eeffyq4DDp5ezSn{margin:0 auto;text-align:center;max-width:260px;color:var(--tg-theme-text-color);margin-bottom:20px}.Urd96JoS9cFVlL66OEKe{font-size:15px;border-radius:10px;height:auto;padding:5px 15px;margin:0 auto 20px auto;background:rgba(0,0,0,0);color:var(--tg-theme-button-color)}.Urd96JoS9cFVlL66OEKe:hover{background:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/introduction/introduction-steps/second-step/second-step.module.scss"],"names":[],"mappings":"AACE,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,iBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA,CAEF,sBACE,aAAA,CACA,iBAAA,CACA,eAAA,CACA,gCAAA,CACA,kBAAA,CAIJ,sBACE,cAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA,CACA,uBAAA,CACA,wBAAA,CACA,kCAAA,CACA,4BACE,wBAAA","sourcesContent":[".secondStep {\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Top {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    margin-bottom: 5px;\n  }\n  &Title {\n    font-size: 21px;\n    font-weight: 500;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n  }\n  &Description {\n    margin: 0 auto;\n    text-align: center;\n    max-width: 260px;\n    color: var(--tg-theme-text-color); \n    margin-bottom: 20px;\n  }\n}\n\n.skip {\n  font-size: 15px;\n  border-radius: 10px;\n  height: auto;\n  padding: 5px 15px;\n  margin: 0 auto 20px auto;\n  background: transparent;\n  color: var(--tg-theme-button-color); \n  &:hover {\n    background: transparent;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondStepImg": `PYvW_1vgNwuFXWIzWCQT`,
	"secondStepTop": `bOESl9fKkXJx45PKzNAX`,
	"secondStepTitle": `SSic_ra0P5N_jGVNXzvP`,
	"secondStepDescription": `dsOf8Eeffyq4DDp5ezSn`,
	"skip": `Urd96JoS9cFVlL66OEKe`
};
export default ___CSS_LOADER_EXPORT___;
