export function formatDateFromTimestamp(timestamp) {
    if (timestamp !== undefined) {
        const dateTimestamp = timestamp * 1000;
        const date = new Date(dateTimestamp);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(2);
        return `${day}.${month}.${year}`;
    }
    return '00.00.00';
}
