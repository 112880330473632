/* eslint-disable max-len */
import { DLB, TON } from './promotion-pay-tokens';
export const modalMocks = [
    {
        token: 'TON',
        tokenLogo: TON,
        amount: '0',
        tokenAddress: ['0:9a8da514d575d20234c3fb1395ee9138f5f1ad838abc905dc42c2389b46bd015', '0:436b4ff60f7bacf75f70d422cfb6b5ae7cd2846f0f3553ae8bcc798258923608']
    },
    {
        token: 'DLB',
        tokenLogo: DLB,
        amount: '0',
        tokenAddress: ['0:90605daea7f8494e554bb33a9ffb53849e958e0607985ec4ebcc77c6d952d401', '0:c288b9102a934e1a93435f6a194470da340559a95e9c9816c682ccff2215149d']
    },
    {
        token: 'KINGY',
        tokenLogo: 'https://i.ibb.co/FbTCKRP/logotokenkingy.png',
        amount: '0',
        tokenAddress: ['0:beb5d4638e860ccf7317296e298fde5b35982f4725b0676dc98b1de987b82ebc', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'DHD',
        tokenLogo: 'https://raw.githubusercontent.com/DHDcoin/website/main/LOGO.png',
        amount: '0',
        tokenAddress: ['0:421705bcb85521f9a99d466358f4dc9e0c469e0d85ddf608091ac89ca6a55c1e', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'BOLT',
        tokenLogo: 'https://cloudflare-ipfs.com/ipfs/QmX47dodUg1acXoxYDULWTNfShXRW5uHrCmoKSUNR9xKQw',
        amount: '0',
        tokenAddress: ['0:f4bdd480fcd79d47dbaf6e037d1229115feb2e7ac0f119e160ebd5d031abdf2e', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'SCALE',
        tokenLogo: 'https://cache.tonapi.io/imgproxy/jHx0m3tMBFj9z9vLy1cooH_v8DIi_2Zi43RLxyfga3g/rs:fill:200:200:1/g:no/aXBmczovL1FtU01pWHNaWU1lZndyVFEzUDZIbkRRYUNwZWNTNEVXTHBnS0s1RVgxRzhpQTg.webp',
        amount: '0',
        tokenAddress: ['0:65aac9b5e380eae928db3c8e238d9bc0d61a9320fdc2bc7a2f6c87d6fedf9208', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'LAVE',
        tokenLogo: 'https://cache.tonapi.io/imgproxy/CgGixr0woAF9rMfbPy51W4XIcjR0r-jAePj8uB521Xc/rs:fill:200:200:1/g:no/aHR0cHM6Ly9pLmliYi5jby9CajVLcUs0L0lNRy0yMDIyMTIxMy0xMTU1NDUtMjA3LnBuZw.webp',
        amount: '0',
        tokenAddress: ['0:65de083a0007638233b6668354e50e44cd4225f1730d66b8b1f19e5d26690751', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'GLINT',
        tokenLogo: 'https://cache.tonapi.io/imgproxy/3OhGnfhUaABk2rXFfJOkWiCyGsI1v7tkYko6QYJpBJ0/rs:fill:200:200:1/g:no/aHR0cHM6Ly9uZnQudG9uLmRpYW1vbmRzL2dsaW50X21ldGEucG5n.webp',
        amount: '0',
        tokenAddress: ['0:81771a4409f10f1f6c14c62d5ae9088e9127fee6a0e3516a8b094b8ab43f862c', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'JETTON',
        tokenLogo: 'https://cache.tonapi.io/imgproxy/RHsJgXPpoaD5YsggBGmCQm6Kd2e7cnznXi07-Z9K5lg/rs:fill:200:200:1/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL0pldFRvbi1Cb3QvSmV0VG9uL21haW4vamV0dG9uLTI1Ni5wbmc.webp',
        amount: '0',
        tokenAddress: ['0:105e5589bc66db15f13c177a12f2cf3b94881da2f4b8e7922c58569176625eb5', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    },
    {
        token: 'STON',
        tokenLogo: 'https://cache.tonapi.io/imgproxy/89yL6-mTGvI_Uw14SlwWCLBdI2nvBO9_6gPssVkuQs0/rs:fill:200:200:1/g:no/aHR0cHM6Ly9zdGF0aWMuc3Rvbi5maS9sb2dvL3N0b25fc3ltYm9sLnBuZw.webp',
        amount: '0',
        tokenAddress: ['0:3690254dc15b2297610cda60744a45f2b710aa4234b89adb630e99d79b01bd4f', '0:d6170e963839321ab3c77e50bdc4c99101a9a9e17ad84544229e65a344685850']
    }
];
