// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LXnOHtAuL6lowbESo5DG{display:flex;align-items:center;justify-content:center;margin-bottom:10px}.ZzwJ6VgyGi0PzxJ1_4mJ{font-size:21px;font-weight:500;text-align:center;color:var(--tg-theme-text-color);margin-bottom:5px}.ZV992Pjj6cUdnak7ak5q{margin:0 auto;text-align:center;color:var(--tg-theme-text-color);margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/download/download.module.scss"],"names":[],"mappings":"AACE,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gCAAA,CACA,iBAAA,CAEF,sBACE,aAAA,CACA,iBAAA,CACA,gCAAA,CACA,kBAAA","sourcesContent":[".download {\n  &Img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n  }\n  &Title {\n    font-size: 21px;\n    font-weight: 500;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n    margin-bottom: 5px;\n  }\n  &Description {\n    margin: 0 auto;\n    text-align: center;\n    color: var(--tg-theme-text-color); \n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadImg": `LXnOHtAuL6lowbESo5DG`,
	"downloadTitle": `ZzwJ6VgyGi0PzxJ1_4mJ`,
	"downloadDescription": `ZV992Pjj6cUdnak7ak5q`
};
export default ___CSS_LOADER_EXPORT___;
