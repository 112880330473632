import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect } from 'react';
import { Title, Text } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { DownloadModal } from '../../../../components/download-modal';
import * as instructionSticker from '../../../../assets/stickers/instruction.json';
import s from './fourth-step.module.scss';
export const FourthStep = ({ handleNextStep, currentStep }) => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: instructionSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    const { t } = useTranslation();
    const TgObj = window.Telegram.WebApp;
    const fourthStep = currentStep === 4;
    function enableBtn(text) {
        TgObj.MainButton.show();
        TgObj.MainButton.enable();
        TgObj.MainButton.text = text;
        TgObj.MainButton.onClick(() => {
            localStorage.setItem('currentIntroductionStep', '5');
            window.location.href = '/introduction';
        });
        TgObj.BackButton.hide();
    }
    useEffect(() => {
        enableBtn(t('common.next-btn'));
    }, [fourthStep]);
    return (_jsxs("div", { className: s.thirdStep, children: [_jsx("div", { className: s.thirdStepImg, children: _jsx(Lottie, { options: approveOptions, height: 140, isClickToPauseDisabled: true, width: 140 }) }), _jsx("div", { className: s.thirdStepTop, children: _jsx(Title, { variant: "h2", className: s.thirdStepTitle, children: t('download-page.title') }) }), _jsx(Text, { className: s.thirdStepDescription, children: t('download-page.subtitle') }), _jsx(DownloadModal, {})] }));
};
