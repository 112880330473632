/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
export class VPN {
    constructor() {
        this._url = 'https://api-vpn.delabteam.com/';
    }
    async get(url, data) {
        try {
            const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    async post(url, data) {
        try {
            const res = await axios.post(`${this._url}${url}`, data);
            return res.data;
        }
        catch (error) {
            console.error(error);
            return undefined;
        }
    }
    async postAuth() {
        const res = await this.post('auth', { tg_data: window.Telegram.WebApp.initData });
        return res.data;
    }
    async getServers() {
        const res = await this.get('getServers', {});
        return res.data;
    }
    async getRates() {
        const res = await this.get('getRates', {});
        return res.data;
    }
    async getKeys() {
        const res = await this.post('getKeys', { tg_data: window.Telegram.WebApp.initData });
        return res.data;
    }
    async activateFree() {
        const data = await this.post('activateFree', { tg_data: window.Telegram.WebApp.initData });
        return data.data;
    }
    async getKey(id_server) {
        const data = await this.post('getKey', { tg_data: window.Telegram.WebApp.initData, id_server });
        return data.data;
    }
    async getActive() {
        const data = await this.post('getActive', { tg_data: window.Telegram.WebApp.initData });
        return data.data;
    }
    async getTransactions() {
        const data = await this.post('getTransactions', { tg_data: window.Telegram.WebApp.initData });
        return data.data;
    }
    async getIpInfo(ip) {
        const data = await this.get('ipInfo', { ip });
        return data;
    }
    async getInfo() {
        const data = await this.post('getInfo', { tg_data: window.Telegram.WebApp.initData });
        return data.data;
    }
    async getAutoKey() {
        const data = await this.post('getAutoKey', { tg_data: window.Telegram.WebApp.initData });
        return data.data;
    }
}
